import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import Recurring from "./Recurring";
import { CSVLink } from "react-csv";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';


export default class TestReviewManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			layoutFilesVersion:new Map(),
			layoutMdsFiles:new Map(),
			layoutFriendlyName:new Map(),
			selectedFiles:new Map(),
			assigedLayouts:[],
			instanceIds:[],
		}

    }
	
	componentDidMount() {
		this.getLoginUsers();
	}
	
	getValue=(event)=>{
		
		let name = event.target.name;
		let res = event.target.value;
	
		this.setState({[event.target.name]:event.target.value});
		if(name == 'selectedUser'){
			$("input[name='customSwitch1']").prop('checked', false);
			$(".layout-ma-list").show();
			let assigedLayouts = this.state.assigedLayouts;
			let instanceIds = this.state.instanceIds;
			//console.log('assigedLayouts->',assigedLayouts);
			if(assigedLayouts){
				assigedLayouts.map((val,i)=>{
					this.setState({[val]:false});
				})
			}
			this.setState({assigedLayouts:[],instanceIds:[]});
			let that = this;
			setTimeout(function(){
				that.UserLayouts(res);
			}, 1000);
		}

	}
	
	uploadLayoutInMongo=()=>{
		let selectedFiles = this.state.selectedFiles;
		let files = Object.fromEntries(selectedFiles.entries());
		let layouts = [];
		for (let prop in files) {
			layouts.push(files[prop]);
		}
		layouts.map((sheetName,i)=>{
			this.uploadLayoutJsonMongoDb(sheetName);
		});
		
	}
	
	uploadMdsJsonMongoDb =(sheetName)=> {
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'mds-file/'+sheetName;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
            const allData = response.data.Fields;
			let currentDate = new Date().toLocaleString("en-US").replace(',','');
			let parentMds = [];
			if(allData){
				let mdsGuid = allData[1][91];
				let version = allData[1][59] ? allData[1][59] : '1.01';
				
				let dates = [];
				let fields = [];
				allData.map((field,i)=>{
					if(i > 0){
						
						let date = new Date(field[98]);
						if(date != 'Invalid Date'){
							dates.push(date);
						}
						
						let notes = field[12] = field[12].replaceAll('\n','');
						notes = notes.replaceAll('<p></p>','');
						let customNotes = field[116] = field[116].replaceAll('\n','');
						customNotes = customNotes.replaceAll('<p></p>','');
						
						let preId = '';
						let parentGuid = '';
						allData.map((pre_field,i)=>{
							if(field[7] && field[7] == pre_field[0]){
								preId = pre_field[117];
							}
							
							if(pre_field[1] == field[2]){
								parentGuid = pre_field[117];
							}
						})

						let oldP = field[16];
						let userArray = oldP.split(',');
						userArray.splice(0, 9);
						
						let uArray = userArray.filter(function(v){return v!==''});
						//let kioskTypes = uArray.toString();
						//kioskTypes = kioskTypes.replaceAll(',','|');
						
						let kioskT = [];
						uArray.map((val,i)=>{
							let fieldk = 'delegate'+parseInt(i+1);
							let fd = {
								[fieldk]: val.trim(),
							}
							kioskT.push(fd);
						});

						/* let strArray = oldP.split(',');
						strArray.splice(9, 15); */

						/* let configStr = strArray.toString();
						configStr = configStr+",["+kioskTypes+"]"; */
						
						field[91] = '';
						field[129] = '';
						field[130] = '';
						field[131] = '';
						
						let rqFields = [];
						let requiredFields = '';
						if(field[142] && field[5] == 'launchpad'){
							let rfStr = field[142].split(',');
							rfStr.map((rfield,i)=>{
								rfield = rfield.split('|');
								rqFields.push(rfield[0]+','+rfield[1]);
							});
							requiredFields = rqFields.join('|');
							
						}
						
						let opFields = [];
						let optionalFields = '';
						if(field[143] && field[5] == 'launchpad'){
							let opStr = field[143].split(',');
							opStr.map((ofield,i)=>{
								ofield = ofield.split('|');
								opFields.push(ofield[0]+','+ofield[1]);
							});
							optionalFields = opFields.join('|');
							
						}
						let createdDate = new Date(field[97]).toLocaleString("en-US").replace(',','');
						let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
						
						let options = [];
						if(field[6]){
							options = field[6].split(',');
						}
						
						/* let dateValidation = [];
						if(field[48] == 'V9' || field[48] == 'V10' || field[48] == 'V11'){
							dateValidation = ["Any", "Past", "Today", "Future"];
						} */
						
						let dateValidation = '';
						if(field[48] == 'V9'){
							dateValidation = 'past'
						}else if(field[48] == 'V10'){
							dateValidation = 'today'
						}else if(field[48] == 'V11'){
							dateValidation = 'future'
						}
						
						let gridLabel = [];
						if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
							let str = field[41] ? field[41].split('_') : [];
							
							if(str.length > 0){
								
								let columns = str[0].split(',');
								let clabel = [];
								columns.map((val,i)=>{
									//let fieldN = 'columnlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: val.trim(),
										['type']: field[5],
									}
									clabel.push(fd);
								});
								
								let rows = str[1].split(',');
								let crow = [];
								rows.map((val,i)=>{
									//let fieldN = 'rowlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: val.trim(),
										['type']: field[5],
									}
									crow.push(fd);
								});
								gridLabel = {
									"ColumnLabelValues": clabel,
									"RowLabelValues": crow,
								}
							}
						}
						
						if(field[5] == 'basicgrid2'){
							let str = field[41] ? field[41].split('_') : [];
							
							if(str.length > 0){
								let columns = str[0].split('|');
								let rows = str[1].split(',');
								let crow = [];
								rows.map((val,i)=>{
									//let fieldN = 'rowlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: val.trim(),
										['type']: field[5],
									}
									crow.push(fd);
								});
								
								let columnslable = [];
								let columnstd = [];
								columns.map((val,i)=>{
									let cm = val.split(':');
									//let fieldN = 'columnlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: cm[0].trim(),
										['type']: field[5],
									}
									
									columnslable.push(fd);
									columnstd.push(cm[1]);
								});
								let columnstd2 = [];
								columnstd.map((val,i)=>{
									let fieldm = 'column'+parseInt(i+1);
									let fds = {
										['ID']: parseInt(i+1),
										['option']: val.trim(),
									}
									
									columnstd2.push(fds);
								});
								
								gridLabel = {
									"ColumnLabelValues": columnslable,
									"RowLabelValues": crow,
									"ColumnDataType": columnstd2,
								}
							}
						}
						
						let optionValue = [];
						if(field[5] == 'weighted'){
							let str = field[41] ? field[41].split(',') : [];
							str.map((val,i)=>{
								let op = val.split(':');
								options.push(op[0]);
								optionValue.push(op[1]);
							});
						}
						
						if(field[5] == 'grid-dropdown'){
							let str = field[41] ? field[41].split('_') : [];
							
							if(str.length > 0){
								let columns = str[0].split(',');
								let clabel = [];
								columns.map((val,i)=>{
									//let fieldN = 'columnlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: val.trim(),
										['type']: field[5],
									}
									clabel.push(fd);
								});
								let rows = str[1].split('|');
								
								//let columnslable = [];
								let rowValue = [];
								let coption = [];
								rows.map((val,i)=>{
									let cm = val.split(':');
									//columnslable.push(cm[0]);
									//rowValue.push(cm[0]);
									let fieldN = 'rowlabel'+parseInt(i+1);
									let fd = {
										['ID']: parseInt(i+1),
										['label']: cm[0].trim(),
										['type']: field[5],
									}
									rowValue.push(fd);
									
									cm.map((val,j)=>{
										if(j > 0){
											let fieldN = 'row'+parseInt(i+1)+'_columnoption'+parseInt(j);
											let fd = {
												['ID']: parseInt(j+1),
												['option']: val.split(','),
											}
											coption.push(fd);
										}
										
									});
									
								});
								
								gridLabel = {
									"ColumnLabelValues": clabel,
									"RowLabelValues": rowValue,
									"ColumnOption": coption,
									//"ColumnDataType": columnstd,
								}
							}
						}
						
						let ctype = field[114];
						let ctypeVal = [];
						let calculationType = [];
						if(field[5] == 'calculation'){
							if(ctype.indexOf(',') > -1) {
								let ctypearr = ctype.split(',');
								ctypearr.map((val,i)=>{
									let fieldN = 'field'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									ctypeVal.push(fd);
								});
								
							}
							
							calculationType = ["Sum", "Average", "Subtract", "Highest Number", "Lowest Number", "Longest Time", "Shortest Time"]
						}
						
						let opVal = [];
						if(options.length > 0){
							options.map((val,i)=>{
								let fieldN = 'option'+parseInt(i+1);
								let fd = {
									[fieldN]: val.trim(),
								}
								opVal.push(fd);
							});
						}
						
						/* let TrackingCode = {'trackingcodelabel1':field[125]};
						
						if(field[125].indexOf(',') > -1) {
							TrackingCode = [];
							let arr = field[125].split(',');
							arr.map((val,i)=>{
								let fieldN = 'trackingcodelabel'+parseInt(i+1);
								let fd = {
									[fieldN]: val.trim(),
								}
								TrackingCode.push(fd);
							});
						} */
						
						let FieldTypeOptions = {
							"Options": opVal.length > 0 ? opVal : '',
							//"OptionsValue": optionValue.length > 0 ? optionValue : '',
							"GridColumns": field[136],
							"GridRows": field[137],
							"GridLabelValue": Object.keys(gridLabel).length !== 0 ? gridLabel : '',
							"CalculationType": field[113],
							//"CalculationType": calculationType.length > 0 ? calculationType : '',
							"CalculationFields": ctypeVal.length > 0 ? ctypeVal : '',
							"WebsiteLink": field[78],
							//"TrackingCode": field[125] ? field[125].split(',') : '',
							//"TrackingCode": TrackingCode.length > 0 ? TrackingCode : '',
							//"DateValidation": dateValidation.length > 0 ? dateValidation : '',
							"DateValidation": dateValidation,
						}
						
						if(field[5] == 'slider'){
							let str = field[41].split(',');
							if(str.length > 1){
								options = {
									"LabelLeft": str[0],
									"LabelRight": str[1],
								}
							}
						
							FieldTypeOptions['Options'] = options;
							FieldTypeOptions['StepValue'] = field[36];
							FieldTypeOptions['MinValue'] = field[50];
							FieldTypeOptions['MaxValue'] = field[51];
						}
						
						const filteredObj2 = (obj) =>
						  Object.entries(obj)
							.filter(([_, value]) => !!value || typeof value === "boolean")
							.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
						
						FieldTypeOptions = filteredObj2(FieldTypeOptions);
						
						let DataLowLimit = '';
						let DataHighLimit = '';
						if(field[5] == 'Q6'){
							DataLowLimit = {
								'number':field[50],
							}
							
							DataHighLimit = {
								'number':field[51],
							}
						}
						
						if(field[5] == 'timer'){
							DataLowLimit = {
								'time':field[50],
							}
							
							DataHighLimit = {
								'time':field[51],
							}
						}
						
						let fieldData = {
							"FieldGUID": field[117],
							"MasterDataSetGUID": mdsGuid,
							"FieldName": field[0].replaceAll("<<", "").replaceAll(">>", "").trim(),
							"FieldNameIdentifier_STATIC": field[61],
							"VisibleText": field[4],
							"FieldType": field[5],
							"FieldTypeOptions": Object.keys(FieldTypeOptions).length !== 0 ? FieldTypeOptions : '',
							"PrefilteredDelegates": kioskT.length > 0 ? kioskT : '',
							"ButtonLabel": field[40],
							"ImageLocationReferenceInfo": field[99],
							"OutputText": field[9],
							"Notes": notes,
							"InternalDataPersistenceLabel": field[110],
							"Tags": field[13],
							"DataAgg": field[3] == 'Y' ? true:false,
							"AutoTags": field[106] ? field[106].replaceAll("<<", "").replaceAll(">>", "").trim() : '',
							"ValidationType": field[48],
							"DataLowLimit": field[5] != 'Q8' ? DataLowLimit : '',
							"DataHighLimit":field[5] != 'Q8' ? DataHighLimit : '',
							"CreatedDate": field[97] ? moment(createdDate).format('YYYY-MM-DD HH:mm:ss') : moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
							"ModifiedDate": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
							"IsActive": field[60] == 'Y' || field[60] == '' ? true:false,
							"Translations": false,
						}
						
						const filteredObj = (obj) =>
						  Object.entries(obj)
							.filter(([_, value]) => !!value || typeof value === "boolean")
							.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
						
						fieldData = filteredObj(fieldData);
						fields.push(fieldData);
						
					}
				})

				let maximumDate = new Date(Math.max.apply(null, dates));
				let minimumDate = new Date(Math.min.apply(null, dates));
				let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
				let minDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
				//let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
				
				parentMds = parentMds.filter(function(x) {
					 return x !== undefined;
				});
				let parentMdsGuid = this.uniqueArray2(parentMds);
				let itemString = parentMdsGuid.toString();
				
				let layout ={
					"MDSGuid": mdsGuid,
					"MDSName": sheetName,
					"CreatedDate": moment(minDate).format('YYYY-MM-DD HH:mm:ss'),
					"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
					'Version': version,
					'Fields': fields,
				}
				
				this.insertMdsJsonMongo(JSON.stringify(layout));
			}

		},function(reason) {
			alert(reason.result.error.message);
		});
	}
	
	uploadLayoutJsonMongoDb = (sheetName) => {
		
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'layout-file/'+sheetName;
		let data = [];
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
            const data = response.data;
			//console.log('data->',data);
			let currentDate = new Date().toLocaleString("en-US").replace(',','');
			let parentMds = [];
			let allData = data.Fields;
			if(allData.length > 0){
				let layoutGuid = allData[1][91];
				let associatedType = allData[1][92];
				let associatedForm = allData[1][93];
				let associatedDevice = allData[1][94];
				let associatedVisit = allData[1][95];
				let version = allData[1][59] ? allData[1][59] : '1.01';
				let friendly_name = allData[1][62] ? allData[1][62] : '';
				let Public = allData[1][111] ? allData[1][111] : 'Yes';
				let PrivateLayoutOwner = allData[1][115] ? allData[1][115] : '';
				let AssignedTrack = allData[1][125] ? allData[1][125].split(',') : '';
				
				let dates = [];
				let fields = [];
				let mdsFiles = [];
				allData.map((field,i)=>{
					if(i > 0){
						let date = new Date(field[98]);
						if(date != 'Invalid Date'){
							dates.push(date);
						}
						
						parentMds.push(field[129]);
						
						mdsFiles.push(field[118]);
								
						let preId = '';
						let parentGuid = '';
						allData.map((pre_field,i)=>{
							if(field[7] && field[7] == pre_field[0]){
								preId = pre_field[117];
							}
							
							if(pre_field[1] == field[2]){
								parentGuid = pre_field[117];
							}
						})

						let oldP = field[16];
						let userArray = oldP.split(',');
						userArray.splice(0, 9);
						
						let uArray = userArray.filter(function(v){return v!==''});
						let kioskTypes = uArray.toString();
						kioskTypes = kioskTypes.replaceAll(',','|');

						
						let fieldData = {
							"FieldGUID": field[131] ? field[131] : field[117],
							"Position#": field[10],
							"Include": field[14] == 'Y' ? true:false,
							"Required": field[15] == 'Y' ? true:false,
							"DelegateTypes": "["+kioskTypes+"]",
							"DefaultLayoutGuid": layoutGuid,
							"ParentGUID": parentGuid,
							"PostMergeEdit": field[17] ? field[17] : 'Yes',
							//"Public": field[111] == 'Y' ? true:false,
						}
						
						const filteredObj = (obj) =>
						  Object.entries(obj)
							.filter(([_, value]) => !!value || typeof value === "boolean")
							.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
						
						fieldData = filteredObj(fieldData);
						fields.push(fieldData);
						
					}
				})

				let minimumDate = new Date(Math.min.apply(null, dates));
				let maximumDate = new Date(Math.max.apply(null, dates));
				let minDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
				let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
				//let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
				
				parentMds = parentMds.filter(function(x) {
					 return x !== undefined;
				});
				
				let parentMdsGuid = this.uniqueArray2(parentMds);
				
				let mdsFilesArray = this.uniqueArray2(mdsFiles);
				
				if(mdsFilesArray.length > 0){
					mdsFilesArray.map((fls,i)=>{
						this.uploadMdsJsonMongoDb(fls);
					});
				}
				
				let itemString = parentMdsGuid.toString();
				
				let layout ={
					"DefaultLayoutName": sheetName,
					"DefaultLayoutGuid": layoutGuid,
					"FriendlyName": friendly_name,
					"Public": Public,
					"PrivateLayoutOwner": PrivateLayoutOwner,
					"IncludedMDSGUIDs": itemString,
					"AssociatedType": associatedType,
					"AssociatedDeviceTypes": associatedDevice,
					"AssociatedVisitTypes": associatedVisit,
					"AssignedTrack": AssignedTrack,
					//"AssociatedTrack": [],
					"Version": version,
					"Mode": 'Build',
					"showInTemplate": false,
					"CreatedDate": moment(minDate).format('YYYY-MM-DD HH:mm:ss'),
					"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
					"Fields": fields,
				}
				//console.log('layout->',layout);
				this.insertLayoutJsonMongo(JSON.stringify(layout));
			}
				
		})
	}
	
	insertMdsJsonMongo(data){
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'insert-mds-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	insertLayoutJsonMongo(data){
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'insert-layout-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//console.log('response::', response.data);
			let that = this;
			setTimeout(function(){
				that.getLayoutJsonFileVersion();
			}, 1000);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	uniqueArray2(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	setUploadToMongo=(event)=>{
		let sheet = event.target.name;
		let type = event.target.id;
		let selectedFiles = this.state.selectedFiles;
		
		if($("#"+type).prop("checked") == true){
			selectedFiles.set(type,sheet);
			this.setState({[type]:true});
		}else{
			selectedFiles.delete(type);
			this.setState({[type]:false});
		}
		
		this.setState({selectedFiles});
	}
	
	getLoginUsers=()=>{
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'login-users';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let users = response.data;
			this.setState({users});
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	convertSecondstoTime(val) {
		let dateObj = new Date(val * 1000);
		let hours = dateObj.getUTCHours();
		let minutes = dateObj.getUTCMinutes();
		let seconds = dateObj.getSeconds();

		/* let timeString = hours.toString().padStart(2, '0')
			+ ':' + minutes.toString().padStart(2, '0')
			+ ':' + seconds.toString().padStart(2, '0'); */
			
		let timeString = minutes.toString().padStart(2, '0')
			+ ':' + seconds.toString().padStart(2, '0');

		return timeString;
	}
	
	filterTrackType=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
		$('.lfilter option:selected').prop("selected", false);
		if(value){
			$(".layout-ma-list").hide();
			let childSheets = this.props.childSheets;
			childSheets.map((sheet,i)=>{
				let Track = sheet.Track ? sheet.Track : [];
				if(Track.indexOf(value) != -1){
					$("#form-"+sheet.LayoutGuid).show();
				}
			})
		}else{
			$(".layout-ma-list").show();
		}
	}
	
	filterLayoutType=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
		let childSheets = this.props.childSheets;
		let layoutType = this.state.layoutType;
		let layoutDeviceType = this.state.layoutDeviceType;
		let layoutVisitType = this.state.layoutVisitType;
		let layoutTrack = this.state.layoutTrack;
		
		if(name == 'layoutType'){
			layoutType = value;
		}else if(name == 'layoutDeviceType'){
			layoutDeviceType = value;
		}else if(name == 'layoutVisitType'){
			layoutVisitType = value;
		}
		
		if(layoutType || layoutDeviceType || layoutVisitType || layoutTrack){
			$(".layout-ma-list").hide();
			childSheets.map((sheet,i)=>{
				let Types = sheet.Types ? sheet.Types : [];
				let dTypes = sheet.DeviceTypes ? sheet.DeviceTypes : [];
				let vTypes = sheet.VisitTypes ? sheet.VisitTypes : [];
				
				if(layoutType && layoutDeviceType && layoutVisitType){
					if(Types.indexOf(layoutType) != -1 && dTypes.indexOf(layoutDeviceType) != -1 && vTypes.indexOf(layoutVisitType) != -1){
						$("#form-"+sheet.LayoutGuid).show();
					}
				}
				
				if(layoutType && layoutDeviceType && !layoutVisitType){
					if(Types.indexOf(layoutType) != -1 && dTypes.indexOf(layoutDeviceType) != -1){
						$("#form-"+sheet.LayoutGuid).show();
					}
				}
				
				if(layoutType && !layoutDeviceType && layoutVisitType){
					if(Types.indexOf(layoutType) != -1 && vTypes.indexOf(layoutVisitType) != -1){
						$("#form-"+sheet.LayoutGuid).show();
					}
				}
				
				if(!layoutType && layoutDeviceType && layoutVisitType){
					if(dTypes.indexOf(layoutDeviceType) != -1 && vTypes.indexOf(layoutVisitType) != -1){
						$("#form-"+sheet.LayoutGuid).show();
					}
				}
				
				if(layoutType && !layoutDeviceType && !layoutVisitType){
					if(Types.indexOf(layoutType) != -1 ){
						$("#form-"+sheet.LayoutGuid).show();
					}
				}
				
				if(!layoutType && layoutDeviceType && !layoutVisitType){
					if(dTypes.indexOf(layoutDeviceType) != -1){
						$("#form-"+sheet.LayoutGuid).show();
					}
				}
				
				if(!layoutType && !layoutDeviceType && layoutVisitType){
					if(vTypes.indexOf(layoutVisitType) != -1){
						$("#form-"+sheet.LayoutGuid).show();
					}
				}
				
				if(layoutTrack){
					let Track = sheet.Track ? sheet.Track : [];
					if(Track.indexOf(layoutTrack) == -1){
						$("#form-"+sheet.LayoutGuid).hide();
					}
				}
			})
			
		}else{
			$(".layout-ma-list").show();
		}
		
		
	}
	
	assignLayoutToUser=(event)=>{
		let selectedInstanceId = this.props.selectedInstanceId;
		let layoutGuid = event.target.name;
		let selectedUser = this.state.selectedUser;
		if(!selectedUser){
			alert('Please select user first!');
			return false;
		}
		
		if($("#"+layoutGuid).prop("checked") == true){
			this.setState({[layoutGuid]:true});
			this.layoutToUser('add',selectedUser,layoutGuid,selectedInstanceId);
		}else if($("#"+layoutGuid).prop("checked") == false){
			this.layoutToUser('delete',selectedUser,layoutGuid,selectedInstanceId);
			this.setState({[layoutGuid]:false});
		}
		
		//this.setState({selectedFiles});
		
	}
	
	assignAllLayoutToUser=(event)=>{
		
		let user = this.state.selectedUser;
		if(!user){
			alert('Please select user first!');
			$("#formAllSelect").prop('checked', false);
			return false;
		}
		
		if($("#formAllSelect").prop("checked") == true){
			let layouts = [];
			this.props.childSheets.map((sheet,i)=>{
				layouts.push(sheet.LayoutGuid);
			})
			if(layouts){
				this.allLayoutToUser(user,layouts);
			}
		}else if($("#formAllSelect").prop("checked") == false){
			let emptylayouts=[]
			this.props.childSheets.map((sheet,i)=>{
				this.setState({[sheet.LayoutGuid]:false});
			})
			this.allLayoutToUser(user,emptylayouts);
		}
	}
	
	allLayoutToUser=(user,assLayouts)=>{
		
		if(user && assLayouts){
			let fieldData = {
				"UserName": user,
				"Layouts": assLayouts
			}
			
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'assign-layout';
			axios({
				method: 'POST',
				url: url,
				data: fieldData,
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				this.UserLayouts(user);
				//console.log('response::', response.data);
			}).catch(error => {
				console.log('error::', error);
			})
		}
	}
	
	layoutToUser=(type,user,layout,selectedInstanceId)=>{
		
		let assigedLayouts = this.state.assigedLayouts;
		let instanceIds = this.state.instanceIds;
		let instance = selectedInstanceId+'-'+layout;
		if(type == 'add'){
			assigedLayouts.push(layout);
			instanceIds.push(instance);
		}else if(type == 'delete'){
			let removeItem = layout;
			assigedLayouts = $.grep(assigedLayouts, function(value) {
			  return value != removeItem;
			});
			
			let removeIns = instance;
			if(instanceIds.length > 0){
				instanceIds = $.grep(instanceIds, function(value) {
				  return value != removeIns;
				});
			}
			
			this.setState({assigedLayouts,instanceIds});
		}
		
		let assLayouts = this.uniqueArray2(assigedLayouts);
		let assInstances = this.uniqueArray2(instanceIds);
		
		if(user && assLayouts){
			let fieldData = {
				"UserName": user,
				"Layouts": assLayouts,
				"assInstances": assInstances
			}
			
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'assign-layout';
			axios({
				method: 'POST',
				url: url,
				data: fieldData,
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				//console.log('response::', response.data);
			}).catch(error => {
				console.log('error::', error);
			})
		}
	}
	
	UserLayouts=(user)=>{
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'user-layouts/'+user;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			const data = response.data;
			if(data){
				let Layouts = data.Layouts;
				if(Layouts){
					let assigedLayouts = this.state.assigedLayouts;
					let instanceIds = this.state.instanceIds;
					this.setState({assigedLayouts:Layouts,instanceIds:data.assInstances ? data.assInstances : []});
					Layouts.map((val,i)=>{
						this.setState({[val]:true});
					})
				}
			}
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	showInTemplate=(event)=>{
		let layoutGuid = event.target.name;
		let ShowTemp = false;
		if($("#template-"+layoutGuid).prop("checked") == true){
			this.setState({['template-'+layoutGuid]:true});
			ShowTemp = true;
		}else if($("#template-"+layoutGuid).prop("checked") == false){
			this.setState({['template-'+layoutGuid]:false});
		}
		
		//this.setState({selectedFiles});
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'show-in-template';
		let data = {
			"LayoutGuid": layoutGuid,
			"ShowTemp": ShowTemp,
		}
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//console.log('response::', response);
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	showAssignedForms=()=>{
		let assigedLayouts = this.state.assigedLayouts;
		if($("input[name='customSwitch1']").prop("checked") == true){
			$(".layout-ma-list").hide();
			if(assigedLayouts){
				assigedLayouts.map((val,i)=>{
					$("#form-"+val).show();
				})
			}
		}else{
			$(".layout-ma-list").show();
			if(assigedLayouts){
				assigedLayouts.map((val,i)=>{
					$("#form-"+val).hide();
				})
			}
		}
	}
	
	render() {
		const {childSheets,flayoutTypes,fdeviceTypes,fvisitTypes,ftrack,selectedInstanceId} = this.props;
		const {users,selectedUser} = this.state;
		console.log('childSheets::', childSheets);
		let optionUsers = '';
		let reviewFields = 0;
		
		if(users){
			optionUsers = users.map(function(val,i) {
				if(val.role != 'Administrator'){
				return (
					<option value={val.username} key={i}>{val.username+'('+val.role+')'}</option>
				);
				}
				
			})
		}
		
		let href = window.location.href.split('?')[0];
		
		return (
			<div className="mds-manager-mongo container">
				
				<div className="row test-header mt-1">
					<div className="col-md-12">
						<h4 className="mb-0 mt-2">Form Test and Review Manager</h4>
					</div>	
					<div className="col-md-12">
						<div className="row">
						
							<div className="col-md-3 col-lg-3 col-6 col-filter">
								<div className="form-group">
									<label>Track</label>
									<select className="form-control" name="layoutTrack" onChange={this.filterTrackType}>
										<option value="">Please Select</option>
										{ftrack.map((val, i) => (
											<option key={i} value={val}>{val}</option>
										))}
									</select>
								</div>
							</div>
						
							<div className="col-md-3 col-lg-3 col-6 col-filter">
								<div className="form-group">
									<label>Type</label>
									<select className="form-control lfilter" name="layoutType" onChange={this.filterLayoutType}>
										<option value="">Please Select</option>
										{flayoutTypes.map((val, i) => (
											<option key={i} value={val}>{val}</option>
										))}
									</select>
								</div>
							</div>
							
							<div className="col-md-3 col-lg-3 col-6 col-filter">
								<div className="form-group">
									<label>Device Type</label>
									<select className="form-control lfilter" name="layoutDeviceType" onChange={this.filterLayoutType}>
										<option value="">Please Select</option>
										{fdeviceTypes.map((val, i) => (
											<option key={i} value={val}>{val}</option>
										))}
									</select>
								</div>
							</div>
							<div className="col-md-3 col-lg-3 col-6 col-filter">
								<div className="form-group">
									<label>Visit Type</label>
									<select className="form-control lfilter" name="layoutVisitType" onChange={this.filterLayoutType}>
										<option value="">Please Select</option>
										{fvisitTypes.map((val, i) => (
											<option key={i} value={val}>{val}</option>
										))}
									</select>
								</div>
							</div>
							<div className="col-md-3 col-lg-3 col-12 col-filter">
								<div className="form-group">
									<label>Users</label>
									<select className="form-control" name="selectedUser" onChange={this.getValue}>
										<option value="">Please Select</option>
										{optionUsers}
									</select>
								</div>
							</div>
							
						</div>
					</div>	
				</div>
				{childSheets ?
				<div className="tst-child-sheets">
					<div className="scroll-box">
						<div className="tst-top-action-icon">
							{ selectedUser ?
							<div className="custom-control custom-switch text-right">
							  <input type="checkbox" className="custom-control-input" id="customSwitch1" name="customSwitch1" onClick={this.showAssignedForms} />
							  <label className="custom-control-label" for="customSwitch1">Only Assigned Forms</label>
							</div>
							:null}
							<table className="table test-review-table">
								<thead>
								<tr> 
									<th>Layout Name</th>
									<th>Friendly Name</th>
									<th>Rating </th>
									<th>Assign for Testing</th>
									<th>Show in Templates</th>
									<th>Tested</th>
									<th>Test Time</th>
									<th>Reviewed</th>
									<th>Fields</th>
									<th>Review Time</th>
								</tr>
								</thead>
								<tbody>
									{childSheets.map((sheet, i) => {
										let sheetGuid = sheet.LayoutGuid;
										let reviewCount = '';
										let classn = '';
										let mdsClass = '';
										let formReview = '';
										let testTile = '';
										let totalTime = '';
										let totalFields = '';
										let userName = '';
										let starScore = 0;
										
										if(sheet.Response){
											if(sheet.Response.ResponseID){
												
												mdsClass = 'has-in-mongo';
												/* let diff = Math.abs(new Date(sheet.Response.StartTime) - new Date(sheet.Response.EndTime));
												let minutes = Math.floor((diff/1000)/60);
												
												let h = Math.floor(minutes / 60);          
												let m = minutes % 60;
												let s = minutes * 60; */
												/* var mins_num = parseFloat(this, 10);
												var seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60));
												totalTime = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString(); */
												userName = sheet.Response.UserName;
												starScore = sheet.Response.StarScore;
												
												var t1 = new Date(sheet.Response.StartTime);
												var t2 = new Date(sheet.Response.EndTime);
												var seconds = (t2 - t1) / 1000;
												
												//console.log('sheet->',sheet);
												//console.log('seconds->',seconds);
												
												if(seconds){
													totalTime = this.convertSecondstoTime(seconds);
												}
												if(sheet.hasReview == true){
													formReview = 'has-review';
													//reviewFields = sheet.Response.reviewFields;
													reviewCount = sheet.Reviews.length; 
													
												}

											}
										
										
											let totalStar = [1,2,3,4,5];
											
											let starHtml = totalStar.map(function(val,i) {
												if(val <= starScore){
													return (
														<i className="fa fa-star rated"></i>
													);
												}else{
													return (
														<i className="fa fa-star-o"></i>
													);
												}
												
											})
											
											
											let lastUpdate = new Date(sheet.ModifiedDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
											
											return(<tr id={'form-'+sheetGuid} key={i} className={"show layout-ma-list"}>
												<td>{sheet.LayoutName} </td>
												<td>{sheet.FriendlyName} </td>
												<td>{starHtml}</td>
												<td><input id={sheetGuid} type="checkbox" name={sheetGuid} checked={classn == 'has-in-mongo' || this.state[sheetGuid] ? 'checked' : ''} onChange={this.assignLayoutToUser} /></td>
												<td><input id={'template-'+sheetGuid} type="checkbox" name={sheetGuid} checked={sheet.showInTemplate || this.state['template-'+sheetGuid] ? 'checked' : ''} onChange={this.showInTemplate} /></td>
												{mdsClass == 'has-in-mongo' ? 
													<td><div data-target={"#layoutDataModal"+sheetGuid} data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle has-in-mongo"} aria-hidden="true"></i></div></td>
												:
													<td><div data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle not-in-mongo"} aria-hidden="true"></i></div></td>
												}
												<td><div data-toggle="modal">{ mdsClass == 'has-in-mongo' ?  totalTime : ''}</div></td>
												{formReview == 'has-review' ? 
													<td><i id={'guid-'+sheetGuid} className={"fa fa-circle has-in-mongo"} aria-hidden="true"></i><img src={href+'/review-2.png'} className="review-img" alt="Field Review" onClick={()=>this.props.improveMode(sheetGuid,sheet.LayoutName)} /> ({reviewCount})</td>
												:
													<td><div className="tst-mongo-val2" data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle not-in-mongo"} aria-hidden="true"></i></div></td>
												}
												<td><div className="tst-mongo-val2" data-toggle="modal">{ formReview == 'has-review' && reviewFields != 0 ?  reviewFields : ''}</div></td>
												<td>
													<div className="tst-mongo-val2" data-toggle="modal">{ formReview == 'has-review' ?  totalTime : ''}</div>
													<div className="modal" id={"layoutDataModal"+sheetGuid} role="dialog">
														<div className="modal-dialog modal-lg">
															<div className="modal-content">
															  <div className="modal-header">
																<h5> Layout Name: {sheet.LayoutName} </h5>
																<button type="button" className="close" data-dismiss="modal">&times;</button>
															  </div>
															  <div className="modal-body">
																	<table className="layout-tbl table-bordered">
																	  <tr>
																		<th>Start Time</th>
																		<th>Finish Time</th>
																		<th>Total minutes to complete form</th>
																	  </tr>
																	  <tr>&nbsp;</tr>
																	  <tr>
																		<td>{sheet.Response.StartTime}</td>
																		<td>{sheet.Response.EndTime}</td>
																		<td>{totalTime}</td>
																	  </tr>
																	</table>
															  </div>
															</div>
														</div>
													</div>
												</td>
											</tr>)
										}
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				:null}
			</div>
		);	
	}	
}
