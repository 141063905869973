import React from 'react'
import $ from 'jquery';
import '../containers/App.css';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import config from "../config";
import { gapi } from 'gapi-script';
import axios from 'axios';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            username:'',
            password:'',
            errors : ''
        }
        this.btn_submit = React.createRef();

		if( localStorage.getItem('username')){
			this.props.history.push('/');
        }
    }
    validateForm = () => {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }
    setUsername = (event) => {
        this.setState({username:event.target.value})
    }
    setPassword = (event) => {
        this.setState({password:event.target.value})
    }
	
	getUrlParameter(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
	
    handleSubmit = async (event) => {
		let mod = '';
		if(typeof(this.props.location.state) != "undefined" && this.props.location.state !== null) {
			mod = this.props.location.state.moduleName;
		}
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		//let apiKey = $('#apiKey').val();
		let instance = $('#instance').val();
		let subdirectory = $('#subdirectory').val();
		
        event.preventDefault();
        const btn_submit = this.btn_submit.current;
        btn_submit.setAttribute("disabled", true);
		
		let Username  = this.state.username;
		let Password  = this.state.password;
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'login-user';
		
		let userData = {
			"username": Username,
			"password": Password
		}
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(userData),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let data = response.data;
			let login = false;
			if(data.username){
				let uid = this.getUrlParameter('uid');
				let wid = this.getUrlParameter('wid');
				let substring = '';
				if(uid){
					substring = '?uid='+uid;
				}
				
				if(wid){
					substring = '?wid='+wid;
				}

				localStorage.setItem('role', data.role);
				localStorage.setItem('user_id', data.user_guid);
				localStorage.setItem('user_facility', data.facility_name);
				localStorage.setItem('user_facility_id', data.facility_guid);
				localStorage.setItem('user_login_date', date);
				localStorage.setItem('assignInstances', data.assignInstances);
				localStorage.setItem('assignedMdsFiles', data.assignedMdsFiles);
				localStorage.setItem('addMdsCapability', data.addMdsCapability);
				
				$.getJSON("https://api.ipify.org/?format=json", function(e) {
					localStorage.setItem('user_ip', e.ip);
				});
				
				localStorage.setItem('username', this.state.username);
				//this.userLogs();
				if(subdirectory){
					this.props.history.push('/'+subdirectory+substring);
				}else{
					this.props.history.push('/'+substring);
				}
			}else{
				btn_submit.removeAttribute("disabled");
				this.setState({errors:'Invalid username or password.'})
			}
		}).catch(error => {
			console.log('error::', error.response);
		})
    }
	
	userLogs = () => {
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'add-user-logs';
		let date = localStorage.getItem('user_login_date');
		let user = localStorage.getItem('username');
		let ip = localStorage.getItem('user_ip');
		
		let formData = new FormData();
		formData.append('LoginDate', date);
		formData.append('LoginUsername', user);
		formData.append('LoginIpAddress', ip);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			console.log('response::', response);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	showPassword=(type)=>{
		if(type){
			this.setState({showPasswordTxt:true});
			$('#passwordField').attr('type', 'text');
		}else{
			this.setState({showPasswordTxt:false});
			$('#passwordField').attr('type', 'password');
		}
	}
	
    render() {
        return (<div className="container">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h5 className="card-title text-center">Sign In</h5>

                                { this.state.errors &&
                                <div className="alert alert-danger"> { this.state.errors } </div> }

                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup controlId="username">
                                      <FormLabel>Username</FormLabel>
                                      <FormControl
                                        autoFocus
                                        type="text"
                                        onChange={this.setUsername}
                                      />
                                    </FormGroup>
                                    <FormGroup controlId="password">
                                      <FormLabel>Password</FormLabel>
                                      <FormControl
									   id={"passwordField"}
                                        type="password"
                                        onChange={this.setPassword}
                                      />
									{this.state.showPasswordTxt ? 
										<i id="showPassword" className="fa fa-eye" aria-hidden="true" onClick={()=>this.showPassword(false)}></i>
										:
										<i id="showPassword" className="fa fa-eye-slash" aria-hidden="true" onClick={()=>this.showPassword(true)}></i>
									}
                                    </FormGroup>
                                    <Button block disabled={!this.validateForm()} type="submit" ref={this.btn_submit}>
                                      Login
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        )
    }

}

export default Login