import React from 'react'; 
import $ from 'jquery';
import config from "../config";
import guid from "./GUID";
import { gapi } from 'gapi-script';
import axios from 'axios';
import { FieldLink } from "../component/Fields/Link";
//import { BasicGridTable } from "../component/Fields/BasicGrid";
//import { BasicGrid2Table } from "../component/Fields/BasicGrid2";
//import { Grid5PointTable } from "../component/Fields/Grid5Point";
//import { GridCheckTable } from "../component/Fields/GridCheck";
import { WeightedCalTable } from "../component/Fields/WeightedCalculation";
//import { GridDropdownTable } from "../component/Fields/GridDropdown";
//import { GridMultiCheckTable } from "../component/Fields/GridMultiCheck";
//import { GridSingleCheckTable } from "../component/Fields/GridSingleCheck";

import BasicGridTable from "../component/Fields/BasicGrid.js";
import BasicGrid2Table from "../component/Fields/BasicGrid2.js";
import Grid5PointTable from "../component/Fields/Grid5Point.js";
import GridCheckTable from "../component/Fields/GridCheck.js";
import GridDropdownTable from "../component/Fields/GridDropdown.js";
import GridSingleCheckTable from "../component/Fields/GridSingleCheck.js";
import GridMultiCheckTable from "../component/Fields/GridMultiCheck.js";

import FieldQ1 from "../component/Fields/Q1.js";
import FieldQ2 from "../component/Fields/Q2";
import FieldQ2b from "../component/Fields/Q2b";
import FieldQ3 from "../component/Fields/Q3";
import FieldQ4 from "../component/Fields/Q4";
import FieldQ5 from "../component/Fields/Q5";
import FieldQ6 from "../component/Fields/Q6";
import FieldQ7 from "../component/Fields/Q7";
import FieldQ8 from "../component/Fields/Q8";
import FieldQ9 from "../component/Fields/Q9";
import FieldQ10 from "../component/Fields/Q10";
import FieldQ11 from "../component/Fields/Q11";
import FieldQ11b from "../component/Fields/Q11b";
import FieldQ12 from "../component/Fields/Q12";
import FieldQ13 from "../component/Fields/Q13";
import FieldQ14 from "../component/Fields/Q14";
import FieldSmart from "../component/Fields/Smart";
import LikertTable from "../component/Fields/Likert";

import FieldFile from "../component/Fields/FileUpload.js";
import FieldVideo from "../component/Fields/VideoUpload.js";
import FieldInlineImage from "../component/Fields/InlineImage.js";
import FieldInlineVideo from "../component/Fields/InlineVideo.js";

import TimerHtml from "../component/Fields/Timer";
import Launchpad from "../component/Fields/Launchpad";
import FieldPdfApi from "../component/Fields/PdfApi.js";
import ApiFieldVideo from "../component/Fields/ApiVideoUpload.js";

import ReductionTable from "../component/Fields/ReductionValues";
import SliderHtml from "../component/Fields/Slider";
import FieldSig from "../component/Fields/Sig";
import FieldHidden from "../component/Fields/Hidden";

import FieldCalculation from "../component/Fields/Calculation.js";
import FieldObjFile from "../component/Fields/ObjUpload.js";

import {PQ1,PQ2,PQ2b,PQ3,PQ4,PQ5,PQ6,PQ7,PQ8,PQ9,PQ10} from "../type/Type";

function generatehtmlfromlocation(comment,callback=null,kioskResponse,apiPdfs,outputText){
	const rowData = {
		fieldId: comment[0],
		inputText: outputText ? comment[9] : comment[4],
		outputText: comment[9],
		showOutputText: outputText,
		required: comment[15],
		options: comment[6],
		labels: comment[41],
		visibleTxtLabel: comment[45],
		outputTxtLabel: comment[46],
		validation: comment[48],
		type: comment[5],
		typeMode: comment[138],
		numberColumn: comment[136],
		arraignment: comment[138],
		buttonLabel: comment[40],
		fieldGuid: comment[117],
		min: comment[50],
		max: comment[51],
		step: comment[36],
		link: comment[78],
		analzeVideo: comment[59],
		pdfReport: comment[60],
		dataLink: comment[99],
		kioskResponse: kioskResponse,
		preView:false,
	};
	
	let res = kioskResponse.get(comment[0]);
	
    switch(comment[5].toLowerCase()) {
        case 'q1':
			return (<FieldQ1 data={rowData} func={callback} />); 
        break;
		
		case 'q2':
			return <FieldQ2 data={rowData} func={callback} />
        break;
		
		case 'q2b':
			return <FieldQ2b data={rowData} func={callback} />
        break;
		
		case 'q3':
			return <FieldQ3 data={rowData} func={callback} />
        break;
		
		case 'q4':
			return <FieldQ4 data={rowData} func={callback} />
        break;
		
		case 'q5':
			return (<div className="answer-q5"><FieldQ5 data={rowData} func={callback} /></div>);
        break;
		
		case 'q6':
			return <FieldQ6 data={rowData} func={callback} />
        break;
		
		case 'q7':
			return <FieldQ7 data={rowData} func={callback} />
        break;
		
		case 'q8':
			return <FieldQ8 data={rowData} func={callback} />
        break;
		
		case 'q9':
			return <FieldQ9 data={rowData} func={callback} />
        break;
		
		case 'q10':
			return <FieldQ10 data={rowData} func={callback} />;
        break;
		
		case 'q11':
			return <FieldQ11 data={rowData} func={callback} />;
        break;
		
		case 'q11b':
			return <FieldQ11b data={rowData} func={callback} />;
        break;
		
		case 'q12':
			return <FieldQ12 data={rowData} func={callback} />;
        break;
		
		case 'q13':
			return <FieldQ13 data={rowData} func={callback} />;
        break;
		
		case 'q14':
			return <FieldQ14 data={rowData} func={callback} />;
        break;
		
		case 'smart':
			return <FieldSmart data={rowData} func={callback} />;
        break;
		
		case 'likert':
			return <LikertTable data={rowData} func={callback} />;
        break;
		
		case 'launchpad':
			return <Launchpad data={rowData} func={callback} />;
        break;
		
		case 'image':
			return <FieldInlineImage data={rowData} func={callback} />;
        break;
		
		case 'inlinevideo':
			return <FieldInlineVideo data={rowData} func={callback} />;
        break;
		
		case 'pdfapi':
			return <FieldPdfApi dataField={res} data={rowData} apiPdfs={apiPdfs} func={callback} />;
        break;
		
		case 'reduction':
			return <ReductionTable data={rowData} func={callback} />;
        break;
		
		case 'slider':
			return <SliderHtml data={rowData} func={callback} />;
        break;
		
		case 'calculation':
		case 'wcalculation':
			return <FieldCalculation fieldId={comment[0]} question={comment[4]} />;
        break;
		
		
			
		/* case 'q12':
			let q12 = FieldQ12(rowData);
			return q12;
            
        break; */
		
		case 'link':
			let Link = FieldLink(rowData);
			return Link;
            
        break;
		
		case 'sig':
			return <FieldSig data={rowData} func={callback} />;
        break;
		
		case 'hidden':
			return <FieldHidden data={rowData} func={callback} />;
        break;
		
		/* case 'slider':
			let Slider = SliderHtml(rowData);
			return (
			<div>
            {Slider}
			</div>
        );
        break; */
		
		case 'timer':
			return (<TimerHtml data={rowData} func={callback} />);
        break;
		
		
		case 'basicgrid':
			return (<BasicGridTable data={rowData} func={callback} />);
        break;
		
		case 'basicgrid2':
			return (<BasicGrid2Table data={rowData} func={callback} />);
        break;
		
		case 'grid5point':
			return (<Grid5PointTable data={rowData} func={callback} />);
        break;
		
		case 'gridcheck':
			return (<GridCheckTable data={rowData} func={callback} />);
        break;
		
		case 'grid-dropdown':
			return (<GridDropdownTable data={rowData} func={callback} />);
        break;
		
		case 'grid-singlecheck':
			return (<GridSingleCheckTable data={rowData} func={callback} />);
        break;
		
		case 'grid-multicheck':
			return (<GridMultiCheckTable data={rowData} func={callback} />);
        break;
		
		/* case 'basicgrid':
			let BasicGrid = BasicGridTable(rowData);
			return (
			<div>
            {BasicGrid}
			</div>
        );
        break; 
		
		case 'basicgrid2':
			let BasicGrid2 = BasicGrid2Table(rowData);
			return (
			<div>
            {BasicGrid2}
			</div>
        );
        break;
		
		case 'grid5point':
			let Grid5Point = Grid5PointTable(rowData);
			return (<div>
            {Grid5Point}
			</div>);
			
		break;
		
		case 'gridcheck':
			let GridCheck = GridCheckTable(rowData);
			return (<div>
            {GridCheck}
			</div>);
			
		break;
		*/
		case 'weighted':
			let Weighted = WeightedCalTable(rowData);
			return (<div>
            {Weighted}
			</div>);
			
		break;
		
		/* case 'grid-dropdown':
			let GridDropdown = GridDropdownTable(rowData);
			return (<div>
            {GridDropdown}
			</div>);
			
		break; */
		
		/* case 'grid-multicheck':
			let GridMultiCheck = GridMultiCheckTable(rowData);
			return (<div>
            {GridMultiCheck}
			</div>);
			
		break; */
		
		/* case 'grid-singlecheck':
			let GridSingleCheck = GridSingleCheckTable(rowData);
			return (<div>
            {GridSingleCheck}
			</div>);
			
		break; */
		
		/* case 'reduction':
			let Reduction = ReductionTable(rowData);
			return (<div>
            {Reduction}
			</div>);
			
		break; */
		
		/* case 'launchpad':
			let Launchpad = LaunchpadAPI(rowData);
			return (<div>
            {Launchpad}
			</div>);
			
		break; */
		
		case 'video':
			return (<FieldVideo dataField={res} func={callback} data={rowData} />);
			
		break;
		
		case 'apivideo':
			return (<ApiFieldVideo dataField={res} data={rowData} func={callback}/>);
			
		break;
		
		case 'file':
			
			return (<FieldFile dataField={res} func={callback} data={rowData} />);
			
		break;
		
		case 'obj':
			
			return (<FieldObjFile dataField={res} func={callback} data={rowData} />);
			
		break;
		
		
        default:
			return  '';
    }
}

function getConfigResponse(formDataSpreadsheetId){
	
	return new Promise((resolve, reject) => {
		//let spreadsheetID = config.saveOutputspreadsheetId; 
		let spreadsheetID = formDataSpreadsheetId; 
		let params1 = {
			spreadsheetId: spreadsheetID,
		};
		
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetID,
				range:'Sheet1',
			})
			.then(response => {
				let data = response.result.values;
				let configResponse = [];
				
				for(let i in data){
					
					let file_no = 0;
					i = parseInt(i);

					for(let k in data[i]){
						let inner_data = { 'FieldID':'', 'FieldGUID':'', 'response': '', 'feedNote': '', 'UserID':'', 'UserClass':'', 'Date':''}
						k = parseInt(k);

						if(k % 7 ==  0){
							if(i < 5){
								if(typeof configResponse[file_no] == 'undefined' ){
									configResponse[file_no] = []
								}
								configResponse[file_no].push(data[i][k])
							}
							
							if(i > 6){
								if(typeof configResponse[file_no][4] == 'undefined' ){
									configResponse[file_no][4] = [];
								}
								//console.log('data->',data[i]);
								let guid_key = k+1;
								let res_key = k+2;
								let need_key = k+3;
								let user_id_key = k+4;
								let user_class = k+5;
								let date = k+6;
								inner_data['FieldID'] = data[i][k];
								if(typeof data[i][guid_key] != 'undefined') {
									inner_data['FieldGUID'] = data[i][guid_key];
								}
								if(typeof data[i][res_key] != 'undefined') {
									inner_data['response'] = data[i][res_key];
								}
								if(typeof data[i][need_key] != 'undefined') {
									inner_data['feedNote'] = data[i][need_key];
								}
								if(typeof data[i][user_id_key] != 'undefined') {
									inner_data['UserID'] = data[i][user_id_key];
								}
								if(typeof data[i][user_class] != 'undefined') {
									inner_data['UserClass'] = data[i][user_class];
								}
								if(typeof data[i][date] != 'undefined') {
									inner_data['Date'] = data[i][date];
								}
								configResponse[file_no][4].push(inner_data)
							}

							file_no++;
							
						}
						
					}
				}
				resolve(configResponse);
			},
			)
		});
	});
}

function generatehtmlfrom(rowAllData,callback = null,props){ 
    
	switch(rowAllData[5].toLowerCase()) {
        
        case 'q1':
            return  <PQ1 allData={props.allData} 
                        rowAllData={rowAllData}
                        kioskResponse={props.kioskResponse}
						/>;
        break;
		
        case 'q2':
            return  <PQ2 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'q2b':
            return  <PQ2b func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
        case 'q3':
            return  <PQ3 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q4':
            return  <PQ4 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q5':
            return  <PQ5 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q6':
            return  <PQ6 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q7':
           return   <PQ7 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q8':
           return   <PQ8 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q9':
           return   <PQ9 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q10':
           return   <PQ10 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        default:
			return  '';
    }
}

export {generatehtmlfromlocation,getConfigResponse,generatehtmlfrom}; 
