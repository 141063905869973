import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'

export default class MongoLookDataManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			users:[],
			mdsSheets:[],
			rights:[],
			errorPassword:false,
			loginUserRole:'',
			rightsCreate:'',
			addMdsCapability:'On',
			NewType:'Orthotic',
		}

    }
	
	componentDidMount() {
		this.getLoginUsers();
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		
	}
	
	uniqueArray2(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	getLoginUsers=()=>{
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'login-users';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let users = response.data;
			this.setState({users});
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	deleteField=(user)=>{
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'user-delete/'+user;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			alert('User deleted!');
			this.getLoginUsers();
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	
	addDelegateType=()=>{
		let delegateTypes = this.props.delegateTypes;
		let newDelegate = this.state.newDelegate;
		let newDelegateCode = this.state.newDelegateCode;
		
		if(newDelegate && newDelegateCode){
			let delegate = [newDelegate+':'+newDelegateCode];
			let esixt = false;
			delegateTypes.map((val, i) => {
				if(val == delegate){
					esixt = true;
				}
			});
			if(esixt){
				alert('DelegateType "'+newDelegate+'" already esixt!');
			}else{
				
				delegateTypes = delegateTypes.concat(delegate);
				
				let mongoApiUrl = config.mongoApiUrl;
				let url = mongoApiUrl+'add-delegate';
				
				axios({
					method: 'POST',
					url: url,
					data: JSON.stringify(delegateTypes),
					headers: {
						'Content-Type': 'application/json',
					}
				})
				.then(response => {
					$('#addDelegete').modal('hide');
					this.props.getLookUpData();
				}).catch(error => {
					let errortxt = error.response.statusText;
					alert('error::'+errortxt);
					console.log('error::', error.response);
				})
			}
		}else{
			alert('Please fill in the name and code for the delegate type!');
		}
	}
	
	deleteDelegate=(val)=>{
		let delegateTypes = this.props.delegateTypes;
		let newDel = [];
		delegateTypes.map((dal) =>{
			if(dal != val){
				newDel.push(dal);
			}
		});
		if(newDel.length > 0){
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'add-delegate';
			
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(newDel),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
		
	}
	
	addDeviceType=()=>{
		let deviceTypes = this.props.deviceTypes;
		let newDeviceType = this.state.newDeviceType;
		let NewType = this.state.NewType;
		let NewAnatomy = this.state.NewAnatomy;
		let esixt = false;
		deviceTypes.map((val, i) => {
			if(val.name == newDeviceType){
				esixt = true;
			}
		});
		if(esixt){
			alert('DeviceType "'+newDeviceType+'" already esixt!');
		}else{
			let fieldData = {
				"name": newDeviceType,
				"type": NewType,
				"anatomy": NewAnatomy,
			}
			
			deviceTypes = deviceTypes.concat(fieldData);
			
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'update-devicetype';
			
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(deviceTypes),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				$('#addDeviceType').modal('hide');
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
	}
	
	updateDeviceType=()=>{
		let deviceTypes = this.props.deviceTypes;
		let upDeviceType = this.state.upDeviceType;
		let upType = this.state.upType;
		let upAnatomy = this.state.upAnatomy;
		let updatedType = [];
		deviceTypes.map((val, i) => {
			if(val.name == upDeviceType){
				val.type = upType;
				val.anatomy = upAnatomy;
			}
			updatedType.push(val)
		});
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'update-devicetype';
		
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(updatedType),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			$('#editDeviceType').modal('hide');
			this.props.getLookUpData();
		}).catch(error => {
			let errortxt = error.response.statusText;
			alert('error::'+errortxt);
			console.log('error::', error.response);
		})
	}
	
	deleteDeviceType=(val)=>{
		let deviceTypes = this.props.deviceTypes;
		let newDel = [];
		deviceTypes.map((dal) =>{
			if(dal.name != val){
				newDel.push(dal);
			}
		});
		if(newDel.length > 0){
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'update-devicetype';
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(newDel),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
	}
	
	addVisitType=()=>{
		let visitTypes = this.props.visitTypes;
		let newVisitType = [this.state.newVisitType];
		let esixt = false;
		visitTypes.map((val, i) => {
			if(val == newVisitType){
				esixt = true;
			}
		});
		if(esixt){
			alert('VisitType "'+newVisitType+'" already esixt!');
		}else{
			visitTypes = visitTypes.concat(newVisitType);
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'update-visittype';
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(visitTypes),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				$('#addVisitTypes').modal('hide');
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
	}
	
	deleteVisitType=(val)=>{
		let visitTypes = this.props.visitTypes;
		let newDel = [];
		visitTypes.map((dal) =>{
			if(dal != val){
				newDel.push(dal);
			}
		});
		if(newDel.length > 0){
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'update-visittype';
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(newDel),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
	}
	
	addAnatomy=()=>{
		let anatomyList = this.props.anatomyList;
		let anatomy = [this.state.newAnatomy];
		let esixt = false;
		anatomyList.map((val, i) => {
			if(val == this.state.newAnatomy){
				esixt = true;
			}
		});
		if(esixt){
			alert('Anatomy "'+this.state.newAnatomy+'" already esixt!');
		}else{
			anatomyList = anatomyList.concat(anatomy);
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'update-anatomy';
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(anatomyList),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				$('#addAnatomy').modal('hide');
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
	}
	
	deleteAnatomy=(val)=>{
		let anatomyList = this.props.anatomyList;
		let newDel = [];
		anatomyList.map((dal) =>{
			if(dal != val){
				newDel.push(dal);
			}
		});
		if(newDel.length > 0){
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'update-anatomy';
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(newDel),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
	}
	
	addAssType=()=>{
		let assTypes = this.props.assTypes;
		let newAssType = [this.state.newAssType];
		let esixt = false;
		assTypes.map((val, i) => {
			if(val == this.state.newAssType){
				esixt = true;
			}
		});
		if(esixt){
			alert('The Associated Type "'+this.state.newAssType+'" already esixt!');
		}else{
			assTypes = assTypes.concat(newAssType);
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'update-asstype';
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(assTypes),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				$('#addAssTypes').modal('hide');
				//alert('The new Associated Type added successfully!');
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
	}
	
	deleteAssType=(val)=>{
		let assTypes = this.props.assTypes;
		let newDel = [];
		assTypes.map((dal) =>{
			if(dal != val){
				newDel.push(dal);
			}
		});
		if(newDel.length > 0){
			let mongoApiUrl = config.mongoApiUrl;
			let url = mongoApiUrl+'update-asstype';
			axios({
				method: 'POST',
				url: url,
				data: JSON.stringify(newDel),
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				this.props.getLookUpData();
			}).catch(error => {
				let errortxt = error.response.statusText;
				alert('error::'+errortxt);
				console.log('error::', error.response);
			})
		}
	}

	editDeviceType=(del)=>{
		let upDeviceType = del.name;
		let upType = del.type;
		let upAnatomy = del.anatomy;
		this.setState({upDeviceType,upType,upAnatomy});
		$('#editDeviceType').modal('show');
	}
	
	editDeletegate=(name,code)=>{
		let upDelegate = name;
		let upDelegateCode = code;
		this.setState({upDelegate,upDelegateCode});
		$('#editDelegete').modal('show');
	}
	
	updateDelegateType=()=>{
		let delegateTypes = this.props.delegateTypes;
		let upDelegate = this.state.upDelegate;
		let upDelegateCode = this.state.upDelegateCode;
		//let delegate = [upDelegate+':'+upDelegateCode]
		let updatedType = [];
		delegateTypes.map((val, i) => {
			let deleg = val.split(':');
			if(deleg[0] == upDelegate){
				let delegate = deleg[0]+':'+upDelegateCode;
				val = delegate;
			}
			updatedType.push(val)
		});
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'add-delegate';
		
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(updatedType),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			$('#editDelegete').modal('hide');
			this.props.getLookUpData();
		}).catch(error => {
			let errortxt = error.response.statusText;
			alert('error::'+errortxt);
			console.log('error::', error.response);
		})
	}
	
	render() {
		//const {mdsSpreadsheetId} = this.state;
		const {users} = this.state;
		const {delegateTypes,assTypes,deviceTypes,visitTypes,anatomyList} = this.props;
		//console.log('delegateTypes->',delegateTypes);
		let instances = this.props.instances;
		
		let anatomyOption = anatomyList.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		return (
			<div className="delegate-manager-mongo custom-container">
				<div className="row">
					<div className="col-sm-6">
						<div className="mongo-header2">
							<div className="mg-user-title"><h5>Delegate Manager</h5></div>
							<div className="mg-add-user"><button type="button" className="btn btn-primary" data-target="#addDelegete" data-toggle="modal">Add Delegate</button></div>
						</div>
						
						<div className="lookuptable table-responsive">
							<table className="table table-bordered user-table table-sm"> 
							<thead>
								<tr>
									<th>Name</th>
									<th>Code</th>
									<th>Action</th>
								</tr>
								</thead>
								<tbody>
								{delegateTypes.map((del, i) => {
									let str = del.split(':');
									
									return(<tr>
										<td className="item">{str[0]} </td>
										<td className="item">{str[1]} </td>
										
										<td className="item extra-width-a">
										   <button type="button" className="btn code-dialog btn-edit" onClick={()=>this.editDeletegate(str[0],str[1])}><i className="fa fa-edit"></i></button><button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() => {if (window.confirm('Are you sure you want to Delete this delegateType?')) this.deleteDelegate(del)}}><i className="fa fa-trash"></i></button>
										</td>
									</tr>)
								})}
								</tbody>
							</table>
						</div>
					</div>
					
					<div className="col-sm-6">
						<div className="mongo-header2">
							<div className="mg-user-title"><h5>VisitTypes Manager</h5></div>
							<div className="mg-add-user"><button type="button" className="btn btn-primary" data-target="#addVisitTypes" data-toggle="modal">Add Visit Type</button></div>
						</div>
						
						<div className="lookuptable table-responsive">
							<table className="table table-bordered user-table table-sm"> 
							<thead>
								<tr>
									<th>Name</th>
									<th>Action</th>
								</tr>
								</thead>
								<tbody>
								{visitTypes.map((del, i) => {
									
									return(<tr>
										<td className="item">{del} </td>
										<td className="item extra-width-a">
										   {/* <button type="button" className="btn code-dialog btn-edit" onClick={()=>this.editUser(del)}><i className="fa fa-edit"></i></button> */}
											<button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() => {if (window.confirm('Are you sure you want to Delete this VisitType?')) this.deleteVisitType(del)}}><i className="fa fa-trash"></i></button>
										</td>
									</tr>)
								})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				
				<div className="row">
				
					<div className="col-sm-6">
						<div>
							<div className="mongo-header2">
								<div className="mg-user-title"><h5>Associated Type Manager</h5></div>
								<div className="mg-add-user"><button type="button" className="btn btn-primary" data-target="#addAssTypes" data-toggle="modal">Add Associated Type</button></div>
							</div>
							
							<div className="lookuptable2 table-responsive">
								<table className="table table-bordered user-table table-sm"> 
								<thead>
									<tr>
										<th>Name</th>
										<th>Action</th>
									</tr>
									</thead>
									<tbody>
									{assTypes.map((del, i) => {
										return(<tr>
											<td className="item">{del} </td>
											
											<td className="item extra-width-a">
											  <button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() => {if (window.confirm('Are you sure you want to Delete this Type?')) this.deleteAssType(del)}}><i className="fa fa-trash"></i></button>
											</td>
										</tr>)
									})}
									</tbody>
								</table>
							</div>
						</div>
						
						<div>
							<div className="mongo-header2">
								<div className="mg-user-title"><h5>Anatomy Manager</h5></div>
								<div className="mg-add-user"><button type="button" className="btn btn-primary" data-target="#addAnatomy" data-toggle="modal">Add Anatomy</button></div>
							</div>
							
							<div className="lookuptable2 table-responsive">
								<table className="table table-bordered user-table table-sm"> 
								<thead>
									<tr>
										<th>Name</th>
										<th>Action</th>
									</tr>
									</thead>
									<tbody>
									{anatomyList.map((del, i) => {
										return(<tr>
											<td className="item">{del} </td>
											
											<td className="item extra-width-a">
											  <button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() => {if (window.confirm('Are you sure you want to Delete this Anatomy?')) this.deleteAnatomy(del)}}><i className="fa fa-trash"></i></button>
											</td>
										</tr>)
									})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					
					<div className="col-sm-6">
						<div className="mongo-header2">
							<div className="mg-user-title"><h5>DeviceTypes Manager</h5></div>
							<div className="mg-add-user"><button type="button" className="btn btn-primary" data-target="#addDeviceType" data-toggle="modal">Add Device Type</button></div>
						</div>
						
						<div className="lookuptable table-responsive">
							<table className="table table-bordered user-table table-sm"> 
							<thead>
								<tr>
									<th>Name</th>
									<th>Type</th>
									<th>Anatomy</th>
									<th>Action</th>
								</tr>
								</thead>
								<tbody>
								{deviceTypes.map((del, i) => {
									
									return(<tr>
										<td className="item">{del.name} </td>
										<td className="item">{del.type} </td>
										<td className="item">{del.anatomy} </td>
										
										<td className="item extra-width-a">
											<button type="button" className="btn code-dialog btn-edit" onClick={()=>this.editDeviceType(del)}><i className="fa fa-edit"></i></button> <button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() => {if (window.confirm('Are you sure you want to Delete this DeviceType?')) this.deleteDeviceType(del.name)}}><i className="fa fa-trash"></i></button>
										</td>
									</tr>)
								})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			
				
				<div className="modal" id={"addDelegete"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Add New delegateType </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
									<label> DelegateType: </label>
									<input className="form-control" type="text" name='newDelegate' onChange={this.getValue} />
								</div>
								<div className="col-md-12">
									<label> Code: </label>
									<input className="form-control" type="text" name='newDelegateCode' onChange={this.getValue} />
								</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.addDelegateType} >Save</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"editDelegete"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Update delegateType </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
									<label> DelegateType: </label>
									<input className="form-control" type="text" name='upDelegate' value={this.state.upDelegate} onChange={this.getValue} disabled/>
								</div>
								<div className="col-md-12">
									<label> Code: </label>
									<input className="form-control" type="text" name='upDelegateCode' value={this.state.upDelegateCode} onChange={this.getValue} />
								</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.updateDelegateType} >Update</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"addDeviceType"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Add New DeviceType </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
									<label> DeviceType: </label>
									<input className="form-control" type="text" name='newDeviceType' onChange={this.getValue} />
								</div>
								<div className="col-md-12">
									<label> Associated Type: </label>
									<select className="form-control" name='NewType' onChange={this.getValue}>
										<option value="Orthotic">Orthotic</option>
										<option value="Prosthetic">Prosthetic</option>
									</select>
								</div>
								<div className="col-md-12">
									<label> Associated Anatomy: </label>
									<select className="form-control" name='NewAnatomy' onChange={this.getValue}>
										<option value={''}>Select Anatomy</option>
										{anatomyOption}
									</select>
								</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.addDeviceType} >Save</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"editDeviceType"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Update DeviceType </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
									<label> DeviceType: </label>
									<input className="form-control" type="text" name='upDeviceType' value={this.state.upDeviceType} onChange={this.getValue} disabled/>
								</div>
								<div className="col-md-12">
									<label> Associated Type: </label>
									<select className="form-control" name='upType' value={this.state.upType} onChange={this.getValue}>
										<option value="Orthotic">Orthotic</option>
										<option value="Prosthetic">Prosthetic</option>
									</select>
								</div>
								<div className="col-md-12">
									<label> Associated Anatomy: </label>
									<select className="form-control" name='upAnatomy' value={this.state.upAnatomy} onChange={this.getValue}>
										<option value={''}>Select Anatomy</option>
										{anatomyOption}
									</select>
								</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.updateDeviceType} >Update</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"addVisitTypes"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Add New VisitType </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
									<label> VisitType: </label>
									<input className="form-control" type="text" name='newVisitType' onChange={this.getValue} />
								</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.addVisitType} >Save</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"addAnatomy"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Add New Anatomy </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
									<label> Anatomy: </label>
									<input className="form-control" type="text" name='newAnatomy' onChange={this.getValue} />
								</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.addAnatomy} >Save</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"addAssTypes"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Add New Associated Type </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
									<label> Associated Type: </label>
									<input className="form-control" type="text" name='newAssType' onChange={this.getValue} />
								</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.addAssType} >Save</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
			</div>
		);	
	}	
}
