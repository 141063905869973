import React,{ Component } from 'react';
import $ from 'jquery';
import { Form,Button } from 'react-bootstrap';

class MainHeader extends Component {
	constructor(props) {
        super(props);
	 	this.state ={

	 	}
    }
   	handleChange(event){
    	this.setState({renderComponent:event.target.value});
    	this.props.getModule(event.target.value);
    }
	
	logout = () => {
		let subdirectory = $('#subdirectory').val();
        localStorage.removeItem('user_id');
        localStorage.removeItem('username');
		localStorage.removeItem('old_role');
		localStorage.removeItem('old_username');
		if(subdirectory){
			window.location = '/'+subdirectory+'/';
		}else{
			window.location = '/';
		}
    }
	
	backLogin = () => {
		
		let old_role = localStorage.getItem('old_role');
		let old_user_id = localStorage.getItem('old_user_id');
		let old_username = localStorage.getItem('old_username');
		let old_user_facility_id = localStorage.getItem('old_user_facility_id');
		let old_user_facility = localStorage.getItem('old_user_facility');
		
		localStorage.setItem('role', old_role);
		localStorage.setItem('user_id', old_user_id);
		localStorage.setItem('user_facility', old_user_facility);
		localStorage.setItem('user_facility_id', old_user_facility_id);
		localStorage.setItem('username', old_username);
		
		localStorage.removeItem('old_role');
		localStorage.removeItem('old_username');
		
		window.location.reload();
    }
	
    render(){
        const { builderOption, dataMode} =this.props;
		let date = new Date();
		let newDate = ("0" + (date.getMonth() + 1)).slice(-2)+''+date.getDate()+''+date.getFullYear();
		let role = localStorage.getItem('role');
		let user_ip = localStorage.getItem('user_ip');
		
		let old_username = localStorage.getItem('old_username');
		
		return (
        <div className="card-header top-header">
            <div className="row">
				<div className="col-md-4 col-lg-6 col-3">
                    <h4>Builder 2.0</h4>
					<div className="instance-version">{'V1.03282025.a'}</div> 
                </div>
				<div className="col-md-3 col-lg-2 col-6">
				
					{role == 'Administrator' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="instance">Instance Manager</option>
							<option value="builder">{dataMode == 'TestForm' ? 'Test Mode' : 'Builder Mode'}</option>
							<option value="improve">Improve Mode</option>
							<option value="mds_mongo">MongoDB MDS Manager</option>
							<option value="layout_mongo">MongoDB Layout Manager</option>
							<option value="test_manager">Test & Review Manager</option>
							<option value="translation">Translation Manager</option>
							<option value="user">User Manager</option>
							<option value="look_data">Look Up Data Manager</option>
						</select>
					:null}
					
					{role == 'Builder' || role == 'MdsBuilder' || role == 'LayoutBuilder' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Builder Mode</option>
						</select>
					:null}
					
					{role == 'Tester' || role == 'NotesLetters' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Test Mode</option>
						</select>
					:null}
					
					{role == 'Reviewer' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Review Mode</option>
						</select>
					:null}
					
					{role == 'Improve' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="improve">Improve Mode</option>
							<option value="test_manager">Test & Review Manager</option>
						</select>
					:null}
					
					
				</div>
				
                <div className="user-top-outer col-md-5 col-lg-4 col-3">
					{user_ip ? 
					<div className="user-ip">You are logged in from IP address: {user_ip}</div>
					:null}
					<div className="user-login-box">
						<div className="login-user-top text-right">
							<p className="mb-0 ">{'Welcome: '+localStorage.getItem('username')+'('+localStorage.getItem('role')+')'}</p>
							{old_username ? 
								<div className="old-user"><span> Back to:</span> <a href="javascript:void(0)" className="xx" onClick={this.backLogin}>{old_username}</a></div>
							:null}
						</div> 
						<div className="aa">
							<a className="nav-l1ink btn btn-outline-info sign-o1ut-btn btn-sm" onClick={this.logout}>Sign Out</a>
						</div>
					</div>
                </div>
            </div>
        </div>            
    )}
	
}

export default MainHeader;