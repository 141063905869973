import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'

export default class MongoUserManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			users:[],
			mdsSheets:[],
			rights:[],
			errorPassword:false,
			loginUserRole:'',
			rightsCreate:'',
			addMdsCapability:'On',
		}

    }
	
	componentDidMount() {
		this.getLoginUsers();
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		
		if(name == 'confirmPassword' && this.state.newPassword){
			if(res == this.state.newPassword){
				$('.pass-error').hide();
				this.setState({errorPassword:false});
			}else{
				$('.pass-error').show();
				this.setState({errorPassword:true});
			}
		}
		
		
		
		
		this.setState({[event.target.name]:event.target.value});
		
		if(name == 'assignInstances' && res){
			res = Array.from(event.target.selectedOptions, (item) => item.value).join();
			let newArray = name+'Array';
			this.setState({[event.target.name]:res,[newArray]:Array.from(event.target.selectedOptions, (item) => item.value)});
			let that = this;
			this.setState({mdsSheets:[]});
			setTimeout(function(){
				that.getMdsSheets();
			}, 1000);
			
		}
		
		if(name == 'assignedMdsFiles' && res){
			res = Array.from(event.target.selectedOptions, (item) => item.value).join();
			let newArray = name+'Array';
			this.setState({[event.target.name]:res,[newArray]:Array.from(event.target.selectedOptions, (item) => item.value)});
		}
		
		if(name == 'assignInstances' && res){
			//this.getMdsSheets(res);
		}	

	}
	
	getMdsSheets=() => {
		let assignInstances = this.state.assignInstancesArray;
		let ApiUrl = config.mongoApiUrl;
		//let url = ApiUrl+'mds-files-ins/'+selectedInstanceId;
		let data = [];
		let mdsFiles = [];
		if(assignInstances.length > 0){
			assignInstances.map((ins) =>{
				let sheetsArr = [];
				let url = ApiUrl+'mds-files-ins/'+ins;
				axios({
					method: 'GET',
					url: url,
					headers: {
						'Content-Type': 'application/json',
					}
				})
				.then(response => {
					const sheets = response.data;
					//console.log('sheets->',sheets);
					//let mdssheetsArray = this.state.mdsSheets.
					let mdsSheets = sheets.map((sheet) =>{
						let sheetTitle = sheet.MDSName;
						if(sheetTitle != 'Temp-File' && sheetTitle.indexOf('Temp-') == -1){
							return sheet;
						}
						
					});
					mdsSheets = mdsSheets.filter(function(x) {
						 return x !== undefined;
					});
					
					//sheetsArr[ins] = mdsSheets;
					mdsFiles.push(mdsSheets);
					this.setState({mdsSheets:mdsFiles});
				})
			});
		}
	}
	
	uniqueArray2(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	getLoginUsers=()=>{
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'login-users';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let users = response.data;
			this.setState({users});
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	deleteField=(user)=>{
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'user-delete/'+user;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			alert('User deleted!');
			this.getLoginUsers();
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	loginAsUser=(data)=>{
		
		let old_username = localStorage.getItem('old_username');
		
		if(!old_username){
			localStorage.setItem('old_role', localStorage.getItem('role'));
			localStorage.setItem('old_user_id', localStorage.getItem('user_id'));
			localStorage.setItem('old_username', localStorage.getItem('username'));
			localStorage.setItem('old_user_facility_id', localStorage.getItem('user_facility_id'));
			localStorage.setItem('old_user_facility', localStorage.getItem('user_facility'));
		}
		
		localStorage.setItem('role', data.role);
		localStorage.setItem('user_id', data.user_guid);
		localStorage.setItem('user_facility', data.facility_name);
		localStorage.setItem('user_facility_id', data.facility_guid);
		localStorage.setItem('assignInstances', data.assignInstances);
		localStorage.setItem('assignedMdsFiles', data.assignedMdsFiles);
		localStorage.setItem('addMdsCapability', data.addMdsCapability);
		localStorage.setItem('username', data.username);
		window.location.reload();
	}
	
	addUser=()=>{
		let loginUser = this.state.loginUser;
		let loginUserRole = this.state.loginUserRole;
		let newPassword = this.state.newPassword;
		let assignInstances = this.state.assignInstances;
		let assignedMdsFiles = this.state.assignedMdsFiles;
		let addMdsCapability = this.state.addMdsCapability;
		
		let rights = this.state.rights;
		
		if(this.state.errorPassword){
			this.setState({newPassword:''});
			return false;
		}
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'add-login-user';
		
		let userData = {
			"username": loginUser,
			"role": loginUserRole,
			//"rights": rights ? rights : [],
			"password": newPassword,
			"user_guid": ulid(),
			"facility_name": "Facility-1",
			"facility_guid": "01G8GMMX092W5BKJNNNHCEEER1"
		}
		
		if(loginUserRole == 'Builder'){
			userData['assignInstances'] = assignInstances;
			userData['assignedMdsFiles'] = assignedMdsFiles;
			userData['addMdsCapability'] = addMdsCapability;
		}
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(userData),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			$('#createUser').modal('hide');
			this.getLoginUsers();
			if(response.data){
				alert(response.data);
			}
			$('#loginUserx').val('');
			$('#loginUserRolex').val('');
			$('#newPasswordx').val('');
			$('#confirmPasswordx').val('');
		}).catch(error => {
			let errortxt = error.response.statusText;
			alert('error::'+errortxt);
			console.log('error::', error.response);
		})
	}
	
	editUser=(user)=>{
		
		let loginUser = user.username;
		let loginUserRole = user.role;
		let addMdsCapability = user.addMdsCapability;
		let assignInstancesArray = user.assignInstances ? user.assignInstances.split(',') : [];
		
		let assignedMdsFilesArray = user.assignedMdsFiles ? user.assignedMdsFiles.split(',') : [];
		//this.getMdsSheets(assignInstance);
		this.setState({loginUser,loginUserRole,assignInstances:user.assignInstances,assignInstancesArray,assignedMdsFiles:user.assignedMdsFiles,assignedMdsFilesArray,addMdsCapability});
		this.setState({mdsSheets:[]});
		let that = this;
		setTimeout(function(){
			if(addMdsCapability == 'On'){
				$("input[name='addMdsCapability']").prop('checked', true);
			}else{
				$("input[name='addMdsCapability']").prop('checked', false);
			}
			that.getMdsSheets();
		}, 1000);
		
		$('#userEditPopup').modal('show');
		$('#newPassword').val('');
		$('#confirmPassword').val('');
		
	}
	
	updateUser=()=>{
		let loginUser = this.state.loginUser;
		let loginUserRole = this.state.loginUserRole;
		let newPassword = this.state.newPassword;
		let confirmPassword = this.state.confirmPassword;
		let rightsCreate = this.state.rightsCreate;
		let rightsTest = this.state.rightsTest;
		let assignInstances = this.state.assignInstances;
		let assignedMdsFiles = this.state.assignedMdsFiles;
		let addMdsCapability = this.state.addMdsCapability;

		if(newPassword && (newPassword != confirmPassword || this.state.errorPassword || confirmPassword == undefined || confirmPassword == '')){
			this.setState({newPassword:''});
			$('.pass-error').show();
			return false;
		}
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'update-login-user';
		
		let userData = {
			"username": loginUser,
			"role": loginUserRole,
			//"rights": rightsCreate ? rightsCreate : rightsTest,
			"password": newPassword,
			"assignInstances": assignInstances,
			"assignedMdsFiles": assignedMdsFiles,
			"addMdsCapability": addMdsCapability,
		}
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(userData),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			$('#userEditPopup').modal('hide');
			this.getLoginUsers();
			if(response.data){
				alert('User Updared Successfully!');
			}
		}).catch(error => {
			let errortxt = error.response.statusText;
			alert('error::'+errortxt);
			console.log('error::', error.response);
		})
	}
	
	addMdsCapability=()=>{
		let addMdsCapability = this.state.addMdsCapability;
		if($("input[name='addMdsCapability']").prop("checked") == true){
			this.setState({addMdsCapability:'On'});
		}else{
			this.setState({addMdsCapability:'Off'});
		}
	}
	
	showPassword=(type)=>{
		if(type){
			this.setState({showPasswordTxt:true});
			$('#newPasswordx').removeClass('custom-password');
		}else{
			this.setState({showPasswordTxt:false});
			$('#newPasswordx').addClass('custom-password');
		}
	}
	
	showPasswordEdit=(type)=>{
		if(type){
			this.setState({showPasswordEditTxt:true});
			$('#newPassword').removeClass('custom-password');
		}else{
			this.setState({showPasswordEditTxt:false});
			$('#newPassword').addClass('custom-password');
		}
	}

	render() {
		//const {mdsSpreadsheetId} = this.state;
		const {users,mdsSheets} = this.state;
		let instances = this.props.instances;
		//console.log('mdsSheets->',mdsSheets);
		let assignSheets = [];
		mdsSheets.map(function(value,i) {
			var arr = Object.keys(value).map(function (key) { return value[key]; });
			arr.map(function(val,i) {
				assignSheets.push(val);
			})
		})
		
		let optionHtml = '';
		if(instances){
			optionHtml = instances.map(function(val,i) {
				return (
					<option value={val.InstanceGuild} key={i}>{val.InstanceName}</option>
				);
			})
		}
		
		let optionHtml2 = '';
		if(assignSheets){
			optionHtml2 = assignSheets.map(function(val,i) {
				return (
					<option value={val.MDSGuid} key={i}>{val.InstanceName+' <-> '+val.MDSName}</option>
				);
			})
		}
		
		return (
			<div className="user-manager-mongo container">
				
				<div className="mongo-header2">
					<div className="mg-user-title"><h5>User Manager</h5></div>
					<div className="mg-add-user"><button type="button" className="btn btn-primary" data-target="#createUser" data-toggle="modal">Add User</button></div>
				</div>
				
				<div className="user-list table-responsive">
					<table className="table table-bordered user-table table-sm"> 
					<thead>
						<tr>
							<th>User Name</th>
							<th>Role</th>
							<th>User Guid</th>
							{/* <th>User Rights</th> */}
							<th>Facility Name</th>
							<th>Facility Guid</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{users.map((user, i) => {
							/* let rights = user.rights;
							if(rights.length > 0){
								rights = rights.toString(',');
							} */
							return(<tr>
								<td className="item">{user.username} </td>
								<td className="item">{user.role} </td>
								<td className="item">{user.user_guid} </td>
								{/* <td className="item">{rights} </td>*/}
								<td className="item">{user.facility_name} </td>
								<td className="item">{user.facility_guid} </td>
								<td className="item extra-width">
									<button type="button" className="btn code-dialog btn-edit" onClick={()=>this.editUser(user)}><i className="fa fa-edit"></i></button>
									<button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() => {if (window.confirm('Are you sure you want to Delete this user?')) this.deleteField(user.username)}}><i className="fa fa-trash"></i></button>
									{ user.username != localStorage.getItem('username') && localStorage.getItem('role') == 'Administrator' ? 
										<button type="button" className="btn code-dialog btn-delete " onClick={() => {if (window.confirm('Are you sure you want to login as '+user.username+'?')) this.loginAsUser(user)}}><i className="fa fa-eye"></i></button>
									:null}
									
								</td>
							</tr>)
						})}
						</tbody>
					</table>
				</div>
				<div className="modal" id={"userEditPopup"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Update User Details </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
								<label> User name: </label>
								<input id="loginUser" className="form-control" type="text" name='loginUser' onChange={this.getValue} value={this.state.loginUser} disabled="disabled"/>
								</div>
								<div className="col-md-12">
								<label> User Role: </label>
								<select className="form-control" id="loginUserRole" value={this.state.loginUserRole} name="loginUserRole" onChange={this.getValue}>
									<option value="">Please Select</option>
									<option value="Administrator">Administrator</option>
									<option value="Builder">Builder</option>
									<option value="MdsBuilder">MDS Builder</option>
									<option value="LayoutBuilder">Layout Builder</option>
									<option value="Tester">Tester</option>
									<option value="Reviewer">Reviewer</option>
									<option value="Creator">Creator</option>
									<option value="Improve">Improve</option>
									<option value="NotesLetters">Notes & Letters</option>
								</select>
								</div>
								{this.state.loginUserRole == 'Builder' || this.state.loginUserRole == 'MdsBuilder' || this.state.loginUserRole == 'LayoutBuilder' ?
									<div className="col-md-12">
										<label> Select Instance: </label>
										<select id="assignInstances" className="form-control custom-select-ins" name="assignInstances" onChange={this.getValue} value={this.state.assignInstancesArray} multiple>
											<option value="">Please Select</option>
											{optionHtml}
										</select>
										
									</div>
								:null }
								
								{ (this.state.loginUserRole == 'Builder' || this.state.loginUserRole == 'MdsBuilder' || this.state.loginUserRole == 'LayoutBuilder') && assignSheets ?
									<div className="col-md-12">
										<label> Select MDS files: </label>
										<select className="form-control custom-select-ins" name="assignedMdsFiles" onChange={this.getValue}  value={this.state.assignedMdsFilesArray} multiple>
											{optionHtml2}
										</select>
										
									</div>
								:null }
								
								{(this.state.loginUserRole == 'Builder' || this.state.loginUserRole == 'MdsBuilder') && this.state.assignedMdsFiles ?
								<div className="add-mds-capability">
								<label className="showAllSwitch">
									<input id="addMdsCapability" type="checkbox" name="addMdsCapability" onClick={this.addMdsCapability}/>
									<span className="slider round showAllSlide"></span>
								</label> Add MDS Capability
								</div>
								:null }
								
								{/* this.state.loginUserRole == 'Tester' ?
								<div className="user-rights">
								<label> Rights: </label>
								<input type="checkbox" value='Create' checked={this.state.rightsCreate} name="rightsCreate" onChange={this.getValue}/> Create
								<br />
								<input type="checkbox" value='Test' checked={this.state.rightsTest} name="rightsTest" onChange={this.getValue} /> Test
								<br />
								<input type="checkbox" value='Test' checked={this.state.rightsFeedback} name="rightsFeedback" onChange={this.getValue} /> Feedback
								</div>
								:null */}
								<div className="col-md-12">
								<label> New Password: </label>
								<input id="newPassword" className="form-control custom-password password-input" type="text" name='newPassword' onChange={this.getValue} />
								{this.state.showPasswordEditTxt ? 
								<i id="showPasswordEdit" className="fa fa-eye" aria-hidden="true" onClick={()=>this.showPasswordEdit(false)}></i>
								:
								<i id="showPasswordEdit" className="fa fa-eye-slash" aria-hidden="true" onClick={()=>this.showPasswordEdit(true)}></i>
								}
								</div>
								<div className="col-md-12">
								<label> Confirm Password: </label>
								<input id="confirmPassword" className="form-control custom-password" type="text" name='confirmPassword' onChange={this.getValue} />
								</div>
								<div className="pass-error" style={{display:'none'}}>Passwords did not match!</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.updateUser} >Update</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				<div className="modal" id={"createUser"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="mb-0"> Add New User </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<div className="col-md-12">
								<label> User name: </label>
								<input id="loginUserx" className="form-control" type="text" name='loginUser' onChange={this.getValue} />
								</div>
								<div className="col-md-12">
								<label> User Role: </label>
								<select id="loginUserRolex" className="form-control" name="loginUserRole" onChange={this.getValue}>
									<option value="">Please Select</option>
									<option value="Administrator">Administrator</option>
									<option value="Builder">Builder</option>
									<option value="MdsBuilder">MDS Builder</option>
									<option value="LayoutBuilder">Layout Builder</option>
									<option value="Tester">Tester</option>
									<option value="Reviewer">Reviewer</option>
									<option value="Creator">Creator</option>
									<option value="Improve">Improve</option>
									<option value="NotesLetters">Notes & Letters</option>
								</select>
								</div>
								{this.state.loginUserRole == 'Builder' ?
									<div className="col-md-12">
										<label> Select Instance: </label>
										<select id="assignInstances" className="form-control custom-select-ins" name="assignInstances" onChange={this.getValue} multiple>
											<option value="">Please Select</option>
											{optionHtml}
										</select>
										
									</div>
								:null }
								
								{ (this.state.loginUserRole == 'Builder' || this.state.loginUserRole == 'MdsBuilder' || this.state.loginUserRole == 'LayoutBuilder') && assignSheets ?
									<div className="col-md-12">
										<label> Select MDS files: </label>
										<select className="form-control custom-select-ins" name="assignedMdsFiles" onChange={this.getValue} multiple>
											{optionHtml2}
										</select>
										
									</div>
								:null }
								
								{this.state.loginUserRole == 'Builder' && this.state.assignedMdsFiles ?
								<div className="add-mds-capability">
								<label className="showAllSwitch">
									<input id="addMdsCapability" type="checkbox" name="addMdsCapability" onClick={this.addMdsCapability}/>
									<span className="slider round showAllSlide"></span>
								</label> Add MDS Capability
								</div>
								:null }
								
								{/* this.state.loginUserRole == 'Tester' ?
								<div className="user-rights">
								<label> Rights: </label>
								<input type="checkbox" value='Create' checked={this.state.rightsCreate == 'Create' ? 'checked' : ''} name="rightsCreate" onChange={this.getValue}/> Create
								<br />
								<input type="checkbox" value='Test' checked={this.state.rightsTest} name="rightsTest" onChange={this.getValue} /> Test
								<br />
								<input type="checkbox" value='Feedback' checked={this.state.rightsFeedback} name="rightsFeedback" onChange={this.getValue} /> Feedback
								</div>
								:null */}
								
								
								
								<div className="col-md-12">
								<label> Password: </label>
								<input id="newPasswordx" className="form-control custom-password password-input" type="text" name='newPassword' onChange={this.getValue} />
								{this.state.showPasswordTxt ? 
								<i id="showPassword" className="fa fa-eye" aria-hidden="true" onClick={()=>this.showPassword(false)}></i>
								:
								<i id="showPassword" className="fa fa-eye-slash" aria-hidden="true" onClick={()=>this.showPassword(true)}></i>
								}
								</div>
								<div className="col-md-12">
								<label> Confirm Password: </label>
								<input id="confirmPasswordx" className="form-control custom-password" type="text" name='confirmPassword' onChange={this.getValue} />
								</div>
								<div className="pass-error" style={{display:'none'}}>Passwords did not match!</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.addUser} >Save</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
			</div>
		);	
	}	
}
